import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import { mq } from "../utils/presets"
import TopImage from "../components/TopImage"
import parse from "html-react-parser"

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  ${mq.tablet} {
    max-width: 1080px;
    margin: 0 auto;
    margin-bottom: 3rem;
  }
`

const Textwrapper = styled.div`
  flex: 1 0 auto;
  width: 100%;
  padding: 1rem;
`

const Button = styled.div`
  display: inline;
  background: #4a4a4a;
  color: white;
  padding: 0.4rem 1.3rem;
  border: none;
  border-radius: 12px;
  text-transform: uppercase;
  font-size: 1rem;
  text-decoration: none;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
`

const JobWrapper = styled.ul`
  display: flex;
  flex-flow: row wrap;
  margin: 0;
  li {
    background: #f3f3f3;
    border: 1px solid lightgrey;
    list-style: none;
    margin: 0;
    a {
      padding: 1rem 1rem 1.5rem;
      display: block;
      text-decoration: none;
    }
    transition: all 0.3s;
    &:hover,
    &:focus {
      transform: translateY(-5px);
    }
  }
`

const JobsPageTemplate = ({ data }) => {
  const page = data.page
  const jobs = data.jobs.nodes
  const featuredImage = page?.featuredImage

  const seo = page?.seo
  const seoTitle = page?.seo?.opengraphTitle || page.title || ``
  const metaDesc = seo.metaDesc || ``
  const seoImage = page?.featuredImage?.node.localFile.childImageSharp.resize

  return (
    <Layout isFrontPage={page.isFrontPage}>
      <Seo
        title={page.title}
        seoTitle={seoTitle}
        description={metaDesc}
        image={seoImage}
        uri={page.uri}
      />
      {!!featuredImage && <TopImage featuredImage={featuredImage} />}
      <Wrapper>
        <Textwrapper>
          <h1>{parse(page.title)}</h1>
          {parse(page.content)}
          <JobWrapper>
            {jobs.map((job, i) => {
              return (
                <li key={i}>
                  <Link to={job.uri}>
                    <h2>{job.title}</h2>
                    {job.seo.metaDesc && <p>{job.seo.metaDesc}</p>}
                    <Button>Zur Stellenausschreibung</Button>
                  </Link>
                </li>
              )
            })}
          </JobWrapper>
        </Textwrapper>
      </Wrapper>
    </Layout>
  )
}

export default JobsPageTemplate

export const query = graphql`
  query ($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      content
      isFrontPage
      uri
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        opengraphTitle
        opengraphDescription
        schema {
          articleType
          pageType
          raw
        }
        breadcrumbs {
          url
          text
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              resize(width: 1200, height: 627) {
                src
                tracedSVG
                width
                height
                aspectRatio
                originalName
              }
              gatsbyImageData(
                width: 2000
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
    jobs: allWpJob {
      nodes {
        title
        content
        uri
        seo {
          metaDesc
        }
      }
    }
  }
`
